
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  validateAddress,
} from '@/utils/validators'
import customerAccounts from '@/services/customerAccounts'
import { EventBus } from '@/utils/event-bus'
import IndustrySelector from './IndustrySelector.vue'
import FileUploader from '@/components/FileUploader.vue'
import AutoCompleteUser from './AutoCompleteUser.vue'
import CustomerAccountSelector from './CustomerAccountSelector.vue'
import { CustomerAccountPurpose, SplitFeatureFlag } from '@/utils/enum'
import { findPermissionByName } from '@/utils/permissions'
import { Tier } from '@/models/Tier'
import { Address, BillingFrequencyType } from '@/models/dto'

@Component({
  components: {
    IndustrySelector,
    FileUploader,
    AutoCompleteUser,
    CustomerAccountSelector,
  }
})
export default class CustomerAccountSidebar extends Vue {
  @Prop({ type: String, default: 'edit' }) readonly mode: string
  @Prop({ type: Number, default: null }) readonly id: number

  accountExecutiveId: number = null
  name: string = null
  address: Address = null
  email: string = null
  phone: string = null
  supportEmail: string = null
  supportPhone: string = null
  industryId: number = null
  nameApprovedForUserInterface: boolean = false
  isTiersEnabled: boolean = false
  tierId: number = null
  isAccountTierAutoSelected: boolean = false
  purpose: string = 'standard'
  notes: string = null
  teamLogoImage: string = null
  enterpriseAccountId: number = null
  canSelfServeCancel: boolean | null = null
  showAddressOptions: boolean = false
  changeImage: boolean = false
  loading: boolean = false
  submitting: boolean = false
  billingFrequencyTypeId: number | null = null

  isNotEmpty = isNotEmpty
  isRequired = isRequired
  validateEmail = validateEmail
  validateAddress = validateAddress

  get isModeAdd(): boolean {
    return this.mode === 'add'
  }

  get isModeEdit(): boolean {
    return this.mode === 'edit'
  }

  get isModeDelete(): boolean {
    return this.mode === 'delete'
  }

  get customerAccount(): any {
    return this.$store.getters['customerAccounts/getCustomerAccountDetail']
  }

  get billingFrequencyTypes(): BillingFrequencyType[] {
    const unsetOption: BillingFrequencyType = {
      id: null,
      label: "",
      key: ""
    };
    return [unsetOption].concat(this.$store.getters['types/billingPaymentTypes'])
  }

  get customerAccountAccountExecutiveId(): number {
    return this.customerAccount?.accountExecutive?.id
  }

  get customerAccountName(): string {
    return this.customerAccount?.name
  }

  get customerAccountAddress(): Address {
    return this.customerAccount?.address
  }

  get customerAccountAddressName(): string {
    return this.customerAccount?.address?.addressName
  }

  get customerAccountEmail(): string {
    return this.customerAccount?.email
  }

  get customerAccountPhone(): string {
    return this.customerAccount?.phone
  }

  get customerAccountSupportEmail(): string {
    return this.customerAccount?.supportEmail
  }

  get customerAccountSupportPhone(): string {
    return this.customerAccount?.supportPhone
  }

  get customerAccountIndustryId(): number {
    return this.customerAccount?.industryId
  }

  get customerAccountNameApprovedForUserInterface(): boolean {
    return this.customerAccount?.nameApprovedForUserInterface
  }

  get customerAccountTierId(): number {
    return this.customerAccount?.tier?.tierId
  }

  get customerAccountPurpose(): CustomerAccountPurpose {
    return this.customerAccount?.purpose || CustomerAccountPurpose.Standard
  }

  get customerAccountIsDemo(): boolean {
    return this.customerAccountPurpose === CustomerAccountPurpose.Demo
  }

  get customerAccountNotes(): string {
    return this.customerAccount?.notes
  }

  get customerAccountAvatar(): string {
    return this.customerAccount?.lightLogoUrl
  }

  get customerAccountEnterpriseAccountId(): number {
    return this.customerAccount?.enterpriseAccountId
  }

  get customerAccountCanSelfServeCancel(): boolean | null {
    return this.customerAccount?.canSelfServeCancel
  }

  get customerAccountBillingFrequency(): number {
    return this.customerAccount?.billingFrequencyTypeId
  }

  get ids(): number[] {
    return [this.id]
  }

  get currentUserProfile(): any {
    return this.$store.getters['auth/currentUserProfile'] || {}
  }

  get tiers(): Tier[] {
    return this.$store.getters['tiers/getTiers'] || []
  }

  get canEditTiers(): boolean {
    return findPermissionByName(
      this.currentUserProfile?.roles,
      'canEditTiers'
    )
  }

  @Watch('id', { immediate: true })
  onIdChanged(id: number): void {
    if (id) {
      this.loadData()
    }
  }

  async mounted() {
    this.isTiersEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled',
      SplitFeatureFlag.ServiceTier
    )
    if (this.isTiersEnabled) {
      await this.$store.dispatch('tiers/fetchAllTiers')
    }
  }

  updatePurpose(isDemo: boolean): void {
    this.purpose = isDemo
      ? CustomerAccountPurpose.Demo
      : CustomerAccountPurpose.Standard
  }

  async loadData() {
    this.loading = true
    if (this.id != this.customerAccount?.id) {
      try {
        const customerAccountResponse = await customerAccounts.getCustomerAccount(
          this.id
        )
        const customerAccount = customerAccountResponse.data

        this.$store.dispatch(
          'customerAccounts/setCustomerAccountDetail',
          { customerAccount: customerAccount },
        )
      } catch (e) {
        console.error(e)
      }
    }

    this.accountExecutiveId = this.customerAccountAccountExecutiveId
    this.name = this.customerAccountName
    this.address = this.customerAccountAddress
    this.email = this.customerAccountEmail
    this.phone = this.customerAccountPhone
    this.supportEmail = this.customerAccountSupportEmail
    this.supportPhone = this.customerAccountSupportPhone
    this.industryId = this.customerAccountIndustryId
    this.nameApprovedForUserInterface = this.customerAccountNameApprovedForUserInterface
    this.tierId = this.customerAccountTierId
    this.purpose = this.customerAccountPurpose
    this.notes = this.customerAccountNotes
    this.enterpriseAccountId = this.customerAccountEnterpriseAccountId
    this.canSelfServeCancel = this.customerAccountCanSelfServeCancel
    this.loading = false
    this.billingFrequencyTypeId = this.customerAccountBillingFrequency
  }

  placeSelected(evt: any): void {
    this.address = evt.place
  }

  accountExecutiveSelected(user: any): void {
    this.accountExecutiveId = user?.userId || null
  }

  selectEnterpriseAccountId(customerAccountId: number): void {
    this.enterpriseAccountId = customerAccountId
  }

  setCustomerAccountBillingFrequency(id: number) {
    this.billingFrequencyTypeId = id
  }

  handleParentAccountSelection(customerAccount: any): void {
    if (!this.tierId && customerAccount.tier) {
      this.tierId = customerAccount.tier.tierId
      this.isAccountTierAutoSelected = true
    }
  }

  clearEnterpriseAccountId(): void {
    this.enterpriseAccountId = null
    if (this.isAccountTierAutoSelected) {
      this.tierId = null
      this.isAccountTierAutoSelected = false
    }
  }

  close(): void {
    this.$store.dispatch('app/closeDialog')
  }

  async deleteCustomerAccount(): Promise<void> {
    this.submitting = true
    try {
      await customerAccounts.deleteCustomerAccount(this.customerAccount?.id)
      this.$router.push({ name: 'customer-accounts' })
      this.close()
    } catch (e) {
      console.error(e)
    }
    this.submitting = false
  }

  async submit() {
    this.submitting = true
    const form = this.$refs['form'] as any
    if (!form.validate()) {
      this.submitting = false
      return
    }
    let id = this.customerAccount?.id || null
    const payload: any = {
      accountExecutiveId: this.accountExecutiveId,
      name: this.name,
      email: this.email,
      phone: this.phone,
      supportEmail: this.supportEmail,
      supportPhone: this.supportPhone,
      industryId: this.industryId,
      enterpriseAccountId: this.enterpriseAccountId,
      nameApprovedForUserInterface: this.nameApprovedForUserInterface,
      tierId: this.tierId,
      purpose: this.purpose,
      notes: this.notes,
      canSelfServeCancel: this.canSelfServeCancel,
      billingFrequencyTypeId: this.billingFrequencyTypeId
    }
    if (this.isModeAdd) {
      payload.companyId = this.currentUserProfile?.companyId
      payload.address = this.address
    }
    if (this.isModeEdit) {
      if (this.address.addressName != this.customerAccountAddressName) {
        payload.address = this.address
      }
    }

    try {
      if (this.isModeAdd) {
        id = await customerAccounts
          .addCustomerAccount(payload)
          .then((data) => data.data)
      }

      if (this.isModeEdit) {
        await customerAccounts.modifyCustomerAccount({
          id: id,
          payload: payload,
        })
      }
    } catch (e) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error Saving Customer Account.',
      })
      console.error(e)
      this.submitting = false
      return
    }

    if (this.teamLogoImage && this.changeImage) {
      try {
        const bodyFormData = new FormData()
        bodyFormData.append('files', this.teamLogoImage[0])
        await customerAccounts.uploadCustomerAccountLightLogo({
          customerAccountId: this.id,
          formData: bodyFormData,
        })
      } catch (e) {
        console.error(e)
      }
    }
    this.submitting = false

    EventBus.$emit('load-customer-account')
    this.$nextTick(() => {
      if (this.isModeAdd) {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Customer Account Added.',
        })
        this.$router.push({
          name: 'customer-accounts.view',
          params: { id: id?.toString() },
        })
      } else if (this.isModeEdit) {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Customer Account Updated.',
        })
        this.$router.push({
          name: 'customer-accounts.view',
          params: { id: id?.toString() },
        })
      }
    })
    this.close()
  }
}
