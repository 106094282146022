var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"customer-accounts-detail-sidebar"},[_c('div',{staticClass:"customer-accounts-detail-sidebar--content"},[(_vm.isModeDelete)?_c('div',[_c('p',{staticClass:"text-error font-16 font-bold"},[_vm._v(" Are you sure you want to delete this Customer Account? ")])]):_c('v-form',{ref:"form"},[_c('div',{staticClass:"margin-b-4"},[_c('v-avatar',{staticStyle:{"margin":"6px 12px 6px"},attrs:{"size":"72"}},[(_vm.customerAccountAvatar)?_c('img',{attrs:{"width":"72","src":_vm.customerAccountAvatar}}):_c('v-icon',{attrs:{"color":"blue","size":"72"}},[_vm._v("person_outline")])],1),_c('div',[_c('a',{attrs:{"id":"customer-accounts-image-upload-link"},on:{"click":function($event){_vm.changeImage = !_vm.changeImage}}},[(!_vm.changeImage)?_c('span',[_vm._v("Change Image")]):_c('span',[_vm._v("Cancel")])]),_c('v-expand-transition',[(_vm.changeImage)?_c('FileUploader',{attrs:{"id":"customer-accounts-file-uploader-component"},model:{value:(_vm.teamLogoImage),callback:function ($$v) {_vm.teamLogoImage=$$v},expression:"teamLogoImage"}}):_vm._e()],1)],1)],1),_c('AutoCompleteUser',{attrs:{"label":"Account Executive","placeholder":"Type to search","areAccountExecutives":"true","initial-user":_vm.accountExecutiveId,"clearable":""},on:{"user-selected":_vm.accountExecutiveSelected}}),_c('CRInput',{attrs:{"id":"customer-accounts-name","label":"Name","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Customer Account Name Is Required',
            error: 'Customer Account Name Is Required',
          }),
        ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.address && _vm.address.addressName)?_c('CRInput',{staticClass:"customer-accounts-address-display",attrs:{"label":"Address","type":"text","clear-icon":"replay","clearable":true,"readonly":true,"value":_vm.address.addressName},on:{"click:clear":() => (_vm.address = null)}}):_c('CRAddressSelectorV2',{staticClass:"margin-b-3",attrs:{"id":"customer-accounts-address-selector","label":"Address","placeholder":"Type to search","legacy-border":"","hide-details":false},on:{"change:place":_vm.placeSelected}}),_c('CRInput',{attrs:{"id":"customer-accounts-email","label":"Account Email","rules":[
          _vm.isRequired(false, _vm.validateEmail, {
            error: 'Email must be valid',
          }),
        ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('CRInput',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"customer-accounts-phone","label":"Account Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('CRInput',{attrs:{"id":"customer-accounts-support-email","label":"Dedicated Support Email","rules":[
          _vm.isRequired(false, _vm.validateEmail, {
            error: 'Email must be valid',
          }),
        ]},model:{value:(_vm.supportEmail),callback:function ($$v) {_vm.supportEmail=$$v},expression:"supportEmail"}}),_c('CRInput',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"customer-accounts-support-phone","label":"Support Phone Number"},model:{value:(_vm.supportPhone),callback:function ($$v) {_vm.supportPhone=$$v},expression:"supportPhone"}}),_c('CRSelect',{attrs:{"value":_vm.customerAccountBillingFrequency,"label":"Default Billing Frequency","item-text":"label","item-value":"id","items":_vm.billingFrequencyTypes,"disabled":_vm.submitting,"loading":_vm.loading,"rules":[]},on:{"change":function($event){return _vm.setCustomerAccountBillingFrequency($event)}}}),_c('IndustrySelector',{ref:"industrySelector",attrs:{"id":"customer-accounts-industry","initial-industry":_vm.industryId},model:{value:(_vm.industryId),callback:function ($$v) {_vm.industryId=$$v},expression:"industryId"}}),_c('CustomerAccountSelector',{attrs:{"customer-account-id":_vm.enterpriseAccountId,"label":"Parent Account","placeholder":"Type to search","clearable":"","hidden-customer-account-ids":_vm.ids},on:{"customer-account":_vm.handleParentAccountSelection,"customer-account-selected":_vm.selectEnterpriseAccountId,"customer-account-cleared":_vm.clearEnterpriseAccountId}}),_c('CRSelect',{attrs:{"label":"Can Self-Serve Cancel Reservations","items":[
          { text: 'Inherit from Parent', value: null },
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ],"return-object":false},model:{value:(_vm.canSelfServeCancel),callback:function ($$v) {_vm.canSelfServeCancel=$$v},expression:"canSelfServeCancel"}}),_c('div',[_vm._v(" Approved for Display "),_c('v-checkbox',{staticClass:"shrink mt-0",attrs:{"id":"customer-account-name-approved-for-user-interface","solo":"","hide-details":"","label":"Account Name"},model:{value:(_vm.nameApprovedForUserInterface),callback:function ($$v) {_vm.nameApprovedForUserInterface=$$v},expression:"nameApprovedForUserInterface"}})],1),(_vm.isTiersEnabled && _vm.canEditTiers)?_c('div',{staticClass:"margin-t-3 margin-b-n3"},[_c('CRSelect',{attrs:{"id":"customer-account-tier","label":"Service Tier","items":_vm.tiers,"item-text":"label","item-value":"tierId"},on:{"input":function($event){_vm.isAccountTierAutoSelected = false}},model:{value:(_vm.tierId),callback:function ($$v) {_vm.tierId=$$v},expression:"tierId"}})],1):_vm._e(),_c('div',[_vm._v(" Demo "),_c('v-checkbox',{staticClass:"shrink mt-0",attrs:{"id":"customer-account-is-demo","input-value":_vm.customerAccountIsDemo,"solo":"","hide-details":"","label":"Demo"},on:{"change":function($event){return _vm.updatePurpose($event)}}})],1),_c('CRTextArea',{attrs:{"id":"customer-accounts-notes`","label":"Notes","placeholder":"Add a comment","rows":4,"auto-grow":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('div',[(_vm.isModeDelete)?_c('CRButton',{staticClass:"customer-accounts-detail-sidebar--action-btn",attrs:{"id":"customer-accounts-detail-delete-btn","loading":_vm.loading || _vm.submitting,"color":"error"},on:{"click":_vm.deleteCustomerAccount}},[_vm._v(" Delete ")]):_c('CRButton',{staticClass:"customer-accounts-detail-sidebar--action-btn",attrs:{"id":"customer-accounts-detail-save-btn","loading":_vm.loading || _vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }