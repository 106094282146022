<template>
  <v-container>
    <!--UPLOAD-->
    <form
      v-if="isInitial || isSaving"
      ref="dropForm"
      enctype="multipart/form-data"
      novalidate
    >
      <v-layout
        id="image-upload"
        justify="center"
        class="dropbox"
        style="text-align: center"
      >
        <v-flex cols="auto">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            accept="image/*"
            class="input-file"
            @change="
              filesChange($event.target.name, $event.target.files)
              fileCount = $event.target.files.length
            "
          />
        </v-flex>
        <v-flex v-if="isInitial" style="padding: 12px">
          Drag your file(s) here to begin
          <br />
          or click to browse
        </v-flex>
        <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
      </v-layout>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess">
      <h2>Loaded {{ value.length }} file(s) successfully.</h2>
      <p>
        <a
          href="javascript:void(0)"
          style="text-decoration: none"
          @click="reset"
        >
          Change file
        </a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Uploaded failed.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </v-container>
</template>

<script>
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
export default {
  name: 'App',
  props: {
    value: {
      type: FileList,
      default: null,
    },
  },
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
  },
  created() {
    this.reset()
    // this.$refs.dropForm.
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.$emit('input', null)
      this.uploadError = null
    },
    filesChange(fieldName, fileList) {
      this.currentStatus = STATUS_SAVING
      // handle file changes
      if (!fileList.length) return
      // append the files to FormData
      // console.log(fileList)
      this.$emit('input', fileList)
      // save it
      this.currentStatus = STATUS_SUCCESS
    },
  },
}
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: transparent;
  color: dimgray;
  min-height: 130px; /* minimum height */
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  height: 130px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightgray; /* when mouse over to the drop zone, change color */
  transition: 0.3s;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
